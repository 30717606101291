<template>
  <v-container fluid>
    <v-overlay v-if="progress">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container v-if="!progress">
      <SortableList
        v-bind:headers="headers"
        v-bind:datas="tmpGroups"
        :handle-add="add"
        :handle-edit="edit"
        :handle-del="del"
        :handle-on-sort-end="onSortEnd"
      />
    </v-container>

    <!-- 部品 -->
    <v-container>
      <!-- 確認ダイアログ -->
      <v-dialog
        v-model="confirmInfo.show"
        :max-width="confirmInfo.maxWidth"
        persistent
      >
        <v-form ref="form" v-model="valid">
          <v-card>
            <!-- <v-card-title v-html="confirmInfo.title" /> -->
            <v-card-text>
              <v-container>
                <v-text-field
                  v-model="categoryName"
                  label="カテゴリ名"
                  :rules="rules.categoryName"
                  required
                />
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn class="mr-3" text @click="confirmInfo.doCancel">
                {{ confirmInfo.textNegative }}
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="confirmInfo.doPositive"
                :disabled="!valid"
              >
                {{ confirmInfo.textPositive }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!-- snack -->
      <v-snackbar
        v-model="snack"
        :timeout="3000"
        :color="snackColor"
        outlined
        right
      >
        {{ snackText }}
        <!-- <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
        </template> -->
      </v-snackbar>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CategoryList",
  components: {
    SortableList: () => import("./components/list/SortableListCategory"),
  },
  data: () => ({
    tmpGroups: [],
    headers: [
      {
        text: "並べ替え",
        value: "",
        align: "start",
        sortable: false,
      },
      {
        text: "表示順",
        value: "order",
        align: "start",
        sortable: false,
      },
      {
        text: "名前",
        value: "name",
        align: "start",
        sortable: false,
      },
      {
        text: "編集",
        value: "edit",
        align: "start",
        sortable: false,
      },
      {
        text: "削除",
        value: "delete",
        align: "start",
        sortable: false,
      },
    ],
    // 確認ダイアログ
    confirmInfo: {
      show: false,
      maxWidth: 300,
      contents: "",
      textPositive: "",
      textNegative: "",
      doPositive: () => {},
      doCancel: () => {},
    },
    // snack
    snack: false,
    snackColor: "",
    snackText: "",

    // ダイアログ(入力)
    valid: true,
    categoryName: "",
    rules: {
      categoryName: [(v) => !!v || "カテゴリ名は必須です"],
    },
  }),
  computed: {
    ...mapGetters(["progress", "groups"]),
  },
  watch: {
    groups: {
      handler: function (newValue) {
        this.tmpGroups = this.createTmpGroups(newValue);
      },
    },
  },
  async mounted() {
    this.fetchGroups();
  },
  methods: {
    ...mapActions([
      "fetchGroups",
      "addGroup",
      "deleteGroup",
      "updateGroup",
      "updateGroupSort",
    ]),
    createTmpGroups(groups) {
      if (!groups) {
        return [];
      }
      const tmpGroups = groups.map((group) => {
        // 部品に必要な要素のみ
        const newData = {};
        newData.id = group.id;
        newData.name = group.name;
        newData.sort = group.sort;
        return newData;
      });
      return tmpGroups;
    },
    add(groupId) {
      console.log(`add: ${groupId}`);
      this.categoryName = "";

      // 確認ダイアログ
      this.confirmInfo = {
        show: true,
        maxWidth: 500,
        title: "追加してよろしいですか？",
        textPositive: "追加する",
        textNegative: "キャンセル",
        doPositive: async () => {
          // 入力チェック
          const submit = this.$refs.form.validate();
          if (!submit) {
            return;
          }

          this.confirmInfo.show = false; // とじる(即)

          // 追加処理
          this.executeAddCategory();
        },
        doCancel: () => {
          this.confirmInfo.show = false; // とじる
        },
      };
    },
    edit(group) {
      console.log(`edit: ${JSON.stringify(group)}`);
      this.categoryName = group.name;

      // 確認ダイアログ
      this.confirmInfo = {
        show: true,
        maxWidth: 500,
        title: "更新してよろしいですか？",
        textPositive: "更新する",
        textNegative: "キャンセル",
        doPositive: async () => {
          // 入力チェック
          const submit = this.$refs.form.validate();
          if (!submit) {
            return;
          }

          this.confirmInfo.show = false; // とじる(即)

          // 更新処理
          this.executeUpdateCategory(group);
        },
        doCancel: () => {
          this.confirmInfo.show = false; // とじる
        },
      };
    },
    del(delInfo) {
      console.log(`del: ${JSON.stringify(delInfo)}`);
      return this.executeDelCategory(delInfo.id);
    },
    async onSortEnd(updatedItems) {
      // console.log(`onSortEnd: ${JSON.stringify(updatedItems)}`);

      // 更新用データ作成
      const items = updatedItems.items.map((item, index) => {
        return {
          groupId: item.id,
          sort: index,
        };
      });
      // console.log(`items(sort)=${JSON.stringify(items)}`);

      // DB 更新
      await this.updateGroupSort({ groups: items });
      this.fetchGroups();

      this.snack = true;
      this.snackColor = "success";
      this.snackText = "表示順を更新しました";
    },
    async executeAddCategory() {
      const group = {
        name: this.categoryName,
        sort: this.groups.length, // 最終要素
      };

      // 追加
      await this.addGroup({ group: group });

      // カテゴリ一覧 再取得
      this.fetchGroups();
    },
    async executeUpdateCategory(group) {
      group.name = this.categoryName;

      // 追加
      await this.updateGroup({ group: group });

      // カテゴリ一覧 再取得
      this.fetchGroups();
    },
    async executeDelCategory(groupId) {
      // 削除
      await this.deleteGroup({ groupId: groupId });

      // カテゴリ一覧 再取得
      this.fetchGroups();
    },
  },
};
</script>
